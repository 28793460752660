<template>
  <div id="app">
    <Loading />
    <MobileNav
      class="home-mobile-nav"
      v-if="$route.name !== 'login' && $route.name !== 'register'"
    />
    <!-- <TopNavBar
      class="top"
      v-if="$route.name !== 'login' && $route.name !== 'register'"
    /> -->
    <NavBar
      class="nav"
      v-if="$route.name !== 'login' && $route.name !== 'register'"
    />
    <router-view />
  </div>
</template>
<script>
import Loading from "./views/Loading";
// import TopNavBar from "@/components/TopNavBar.vue";
import NavBar from "@/components/NavBar.vue";
import MobileNav from "@/components/MobileNav.vue";
export default {
  components: {
    // TopNavBar,
    NavBar,
    MobileNav,
    Loading
},

  metaInfo() {
    return {
      title: "منصة تغطية",
      meta: [
        {
          name: "description",
          content:
            "منصة تغطية  لتقديم افضل الخدمات و الحلول التسويقية",
          id: "desc",
        },

        {
          property: "og:description",
          content: "عروض عقارية تتميز بالشفافية والوضوح",
        },

        { property: "og:site_name", content: "تغطية العقارية" },
      ],
    };
  },
};
</script>
<style lang="scss">
#app {
  direction: rtl;
}
#app {
  .home-mobile-nav {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  #app {
    .top,
    .nav,
    .search {
      display: none;
    }
    .body {
      position: absolute;
      top: 63px;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .home-mobile-nav {
      display: block;
    }
  }
}

@media (max-width: 992px) {
  #app {
    .top,
    .nav {
      display: none;
    }
    .home-mobile-nav {
      display: block;
    }
  }
}
</style>
